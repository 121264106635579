
import { computed, defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { array } from "yup";

interface PlanData {
  id: number;
  name: string;
  product_id: string;
  description: string;
  subscription_duration: string;
  amount: number;
}

export default defineComponent({
  name: "new-plan-modal",
  props: ["coupons", "selectedPlan"],
  emits: ["planSaved"],
  components: {},
  setup(props, context) {
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const closeRef = ref<null | HTMLButtonElement>(null);

    const defaultData = {
      id: 0,
      name: "",

      product_id: "",
      description: "",

      subscription_duration: "",
      amount: 0,
    };
    const targetData = ref<PlanData>({ ...defaultData });
    const plan = computed(() => props.selectedPlan || targetData);
    const rules = ref({
      code: [
        {
          required: true,
          message: "Please input Coupon Code",
          trigger: "blur",
        },
      ],
      name: [
        {
          required: true,
          message: "Please input Coupon Title",
          trigger: "blur",
        },
      ],

      starts_at: [
        {
          required: true,
          message: "Please select Start Date",
          trigger: "change",
        },
      ],

      expires_at: [
        {
          required: true,
          message: "Please select Expire Date",
          trigger: "change",
        },
      ],

      coupons_ids: [
        {
          required: false,
          message: "Please select plan",
          trigger: "change",
        },
      ],
      calculation: [
        {
          required: true,
          message: "Please select calculation method",
          trigger: "change",
        },
      ],
      discount: [
        {
          required: true,
          message: "Please input discount",
          trigger: "change",
        },
      ],

      max_uses: [
        {
          required: true,
          message: "Please input Max uses",
          trigger: "change",
        },
      ],
      period: [
        {
          required: true,
          message: "Please input period",
          trigger: "change",
        },
      ],
    });

    const submit = () => {
      // console.log(targetData.value);
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          let service;
          let message;
          if (plan.value.id) {
            service = ApiService.put(
              `admin/plans/${plan.value.id}`,
              plan.value
            );
            message = "Plan updated successfully!";
          } else {
            service = ApiService.post("admin/plans", plan.value);
            message = "Plan created successfully!";
          }
          return service
            .then(({ data }) => {
              Swal.fire({
                text: message,
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              context.emit("planSaved", data);
              closeRef?.value?.click();
              formRef?.value?.resetFields();
              targetData.value = { ...defaultData };
              return data;
            })
            .catch((err) => {
              const response = err.response.data;
              const message = `
                        <strong>${response.message}</strong>
                        <ul>
                            ${Object.values(response.errors).map(
                              (error) => `<li>${error}</li>`
                            )}
                        </ul>`;
              console.log(message);
              Swal.fire({
                html: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            })
            .finally(() => {
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      submit,
      loading,
      formRef,
      closeRef,
      rules,
      plan,
    };
  },
});
