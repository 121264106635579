<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-8">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Manage Plans</span>
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a plan"
      >
        <a
          href="#"
          class="btn btn-sm btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#modal_new_plan"
          @click="
            this.updatePlanMode = false;
            this.selectedPlan = {};
          "
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="media/icons/duotone/Interface/Plus-Square.svg" />
          </span>
          New Plan
        </a>
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <RemoteTable
        :columns="table.columns"
        :height="table.height"
        :data="table.data"
        :total="total"
        @size-changed="handleSizeChange"
        @current-changed="handleCurrentChange"
      >
        <template v-slot:actions="{ item }">
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#modal_new_plan"
            @click="editPlanClicked(item)"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
          >
            <span class="svg-icon svg-icon-3 svg-icon-success">
              <inline-svg src="media/icons/duotone/Communication/Write.svg" />
            </span>
          </a>

          <a
            @click="deletePlan(item)"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
          >
            <span class="svg-icon svg-icon-3 svg-icon-danger">
              <inline-svg src="media/icons/duotone/General/Trash.svg" />
            </span>
          </a>
        </template>
      </RemoteTable>
    </div>
  </div>
  <NewPlanModal
    :coupons="coupons"
    :selectedPlan="selectedPlan"
    :mode="updatePlanMode"
    @plan-saved="planSaved"
  ></NewPlanModal>
</template>

<script>
import { onMounted } from "vue";
import { setCurrentPageTitle } from "../core/helpers/breadcrumb";
import RemoteTable from "../components/RemoteTable.vue";
import NewPlanModal from "../components/modals/forms/NewPlanModal.vue";
import ApiService from "../core/services/ApiService.ts";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  name: "Plans",
  components: { NewPlanModal, RemoteTable },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Plans");
    });
  },
  mounted() {
    this.getCoupons();
    this.loadPlans();
  },
  data: function () {
    return {
      selectedPlan: {},
      updatePlanMode: false,
      currentPlan: {},
      total: 0,
      table: {
        height: 413,
        data: [],
        columns: [
          {
            value: "name",
            label: "Name",
            width: "120",
          },
          {
            value: "product_id",
            label: "Product ID",
            width: "200",
          },
          {
            value: "amount",
            label: "Amount",
            width: "100",
          },
          {
            value: "description",
            label: "Description",
            width: "300",
          },
          {
            value: "actions",
            fixed: "right",
            label: "Actions",
            width: "200",
          },
        ],
        remote: {
          url: "admin/plans",
          method: "GET",
        },
        pageSizes: [10, 30, 50, 100],
        currentPage: 1,
        perPage: 10,
      },
      coupons: [],
    };
  },
  methods: {
    getCoupons() {
      ApiService.get("admin/coupons", "", {
        params: {
          all: true,
        },
      }).then(({ data }) => {
        this.coupons = data;
      });
    },
    loadPlans() {
      console.log("this.perPage");
      console.log(this.table.perPage);
      console.log("this.perPage");
      ApiService.get(this.table.remote.url, null, {
        params: {
          page: this.currentPage,
          perPage: this.table.perPage,
        },
      }).then(({ data }) => {
        this.table.data = data.data;
        this.total = data.meta.total;
      });
    },
    handleSizeChange(perPage) {
      console.log("perPage");
      console.log(perPage);
      console.log("perPage");
      this.table.perPage = perPage;
      this.loadPlans();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.loadPlans();
    },
    deletePlan(plan) {
      ApiService.delete(`admin/plans/${plan.id}`).then(({ data }) => {
        if (data.success) {
          Swal.fire({
            text: data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            timer: 2000,
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          this.loadPlans();
        } else {
          Swal.fire({
            text: data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
      });
    },
    planSaved(plan) {
      if (!this.updatePlanMode) {
        this.table.data.push(plan);
      } else {
        Object.assign(this.currentPlan, plan);
      }
    },
    getColor(item) {
      const percentage = this.getPercentage(item);
      if (percentage <= 30) return "primary";
      if (percentage <= 60) return "success";
      if (percentage <= 90) return "warning";
      return "danger";
    },
    editPlanClicked(plan) {
      this.updatePlanMode = true;
      this.selectedPlan = { ...plan };
      this.currentPlan = plan;
    },
  },
};
</script>

<style scoped></style>
